// @flow

import React, { useState, useContext } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Lframe } from '@latitude/lframe';
import { Hero } from '@latitude/hero';
import Section from '@latitude/section';
import { Heading4, Heading5 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { StickyNavigation } from '@latitude/sticky-navigation';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  MARGIN,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';
import Layout from '../../components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '../../data/pages/our-company.json';
import { PageContext } from '@/context/PageContext';
import imgHero from '../../images/lframe/L-shape-about-us.png';
import HeroBanner from '@/components/lab-components/HeroBanner';
import valuesCreateTomorrowImg from '../../images/values-create-tomorrow.svg';
import valuesPursueExcellenceImg from '../../images/values-pursue-excellence.svg';
import valuesTakeOwnershipImg from '../../images/values-take-ownership.svg';
import valuesWinTogetherImg from '../../images/values-win-together.svg';

const PlayerWrapper = styled.div`
  position: relative;
  margin: auto;
  padding-top: 170px;
  width: 300px;
  
  @media (min-width: ${BREAKPOINT.SM}) {
    padding-top: 285px;
    width: 500px;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: 400px;
    width: 700px;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const OurCompanyPage = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout>
      <MobileAppInstallPrompts />

      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/about-us/our-company/"
          />
          <title>Our Company | Gem by Latitude</title>
          <meta
            name="description"
            content="Latitude is a leading digital instalments and lending business in Australia and New Zealand. Get in touch today. "
          />
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
            <Lframe bgImage={imgHero} />
            <Hero
              title={PageData.content.hero.title}
              text={PageData.content.hero.text}
              css={`
                background: ${COLOR.GREY6};
                @media (min-width: ${BREAKPOINT.MD}) {
                  .HeroContent {
                    height: 420px;
                  }
                }
              `}
            />
          </>
        )}

        <div className="d-block">
          <StickyNavigation
            items={PageData.nav}
            isSticky={isNavSticky}
            onStickyNavStateChange={setIsNavSticky}
            offsetElem="[data-sticky-navigation-offset]"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        <AboutUsSection />
        <OurValuesSection />

        <Section css={`background-color: #F8F8F8; padding: 60px 0 !important;`}>
          <PlayerWrapper>
            <ReactPlayer
              url={'https://youtu.be/tZ0HehBpYas'}
              className="react-player"
              width="100%"
              height="100%"
              controls
            />
          </PlayerWrapper>
        </Section>
      </main>
    </Layout>
  );
};

const AboutUsSection = () => (
  <Section id="about-us" css={`background-color: #F8F8F8;`}>
    <div className="row justify-content-center">
      <div className="col-12 col-sm-8 text-center">
        <Heading4
          align={ALIGN.CENTER}
          color={COLOR.BLACK}
          marginTop={MARGIN.M24}
          marginBottom={MARGIN.M8}
        >
          We make it possible
        </Heading4>
        <Heading5
          align={ALIGN.CENTER}
          color={COLOR.BLACK}
          fontWeight={FONT_WEIGHT.NORMAL}
        >
          With financing solutions in the moments that matter 
        </Heading5>
        <Text
          align={ALIGN.LEFT}
          color={COLOR.BLACK}
          marginTop={MARGIN.M32}
          marginBottom={MARGIN.M24}
        >
          Gem is there for our customers through all life’s moments – the big, the small, the planned and the unexpected. For now and in the future.
          <br />
          <br />
          We're the brand that helps customers shop and purchase what they need with credit cards, interest-free payment plans and personal loans. We deliver much-needed competition to the major banks and choice to consumers.
          <br />
          <br />
          We help retailers attract customers and grow sales with seamless payment and lending solutions, fast decisions, and valuable insights and sales opportunities with our millions of customers.
          <br />
          <br />
          We’re part of Latitude Financial Services, which looks after customers across New Zealand and Australia and has forged solid, long term partnerships with some of the region’s most iconic and successful retailers, including Harvey Norman, The Warehouse Group, Samsung, Apple. Plus, we partner with 5,500+ brokers to connect customers with the right personal loans, whether for a home renovation, a new car, or a major life purchase.
          <br />
          <br />
          We’re passionate about empowering people to unlock opportunities and reach their goals. By pushing the limits of what’s possible, we inspire others to imagine and create new possibilities.
        </Text>
      </div>
    </div>
  </Section>
);


const OurValuesSection = () => {
  const ImageGroup = ({ imgSrc, text }) => (
    <div css={`
        text-align: center;
        @media (min-width: ${BREAKPOINT.LG}) {
          max-width: 20%;
        }
      `}
      >
      <img src={imgSrc} alt="take-ownership" />
      <p css="
        font-size: 16px;
      ">
        {text}
      </p>
    </div>
  )

  return (
    <Section id="our-values" css={`background-color: #F8F8F8; padding: 60px 0 !important;`}>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8 text-center">
          <Heading4
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            marginTop={MARGIN.M24}
            marginBottom={MARGIN.M8}
          >
            Our values
          </Heading4>
          <Text
            align={ALIGN.LEFT}
            color={COLOR.BLACK}
            marginTop={MARGIN.M32}
            marginBottom={MARGIN.M24}
          >
            Our values guide our actions and decisions, ensuring we deliver the best outcomes ​for our customers, partners, each other and for Latitude.
          </Text>
        </div>
      </div>
      <div css={`
        display: flex;
        justify-content: space-evenly;
        @media (max-width: ${BREAKPOINT.LG}) {
          flex-direction: column;
          align-items: center;
        }
      `}>
        <ImageGroup 
          imgSrc={valuesTakeOwnershipImg}
          text="We step into challenges, execute with grit and take accountability."
        />
        <ImageGroup 
          imgSrc={valuesPursueExcellenceImg}
          text="We act with integrity to deliver high quality outcomes."
        />
        <ImageGroup 
          imgSrc={valuesWinTogetherImg}
          text="We seek different perspectives and unify as one company to win in the market."
        />
        <ImageGroup 
          imgSrc={valuesCreateTomorrowImg}
          text="We think boldly and adapt with agility to innovate and shape the future."
        />
      </div>
    </Section>
  )
};

export default OurCompanyPage;
